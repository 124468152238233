<template>
  <div>
    <vs-popup class="sm:popup-w-40" title="Edit Group" :active="isActive" v-on:update:active="emitModalIsActive">
      <div :class="{'opacity-50 pointer-events-none': loading.initData}">
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Nomor *</label>
            <vs-input class="w-full" type="number" v-model="data.no_urut"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Deskripsi *</label>
            <vs-input class="w-full" v-model="data.deskripsi" />
          </div>
        </div>
        <div class="vx-row mt-base">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="loading.saving" @click="save">
                  <span v-if="loading.saving" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!loading.saving">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import SetupReportRepository from '@/repositories/accounting/setup-report-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'DetailEdit',
  props: ['isActive', 'idReportD'],
  components: {
    ValidationErrors
  },
  watch: {
    isActive (active) {
      if (active) {
        this.getInitData()
      }
    }
  },
  data () {
    return {
      errors: null,
      loading: {
        initData: false,
        saving: false
      },
      data: {}
    }
  },
  methods: {
    getInitData () {
      this.loading.initData = true

      SetupReportRepository.showDetail(this.idReportD)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading.initData = false
        })
    },

    save () {
      this.errors = null
      this.loading.saving = true

      const params = this.buildParams()
      SetupReportRepository.updateDetail(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.saving = false
        })
    },

    buildParams () {
      return {
        id: this.idReportD,
        no_urut: this.data.no_urut,
        deskripsi: this.data.deskripsi,
        status_jumlah: this.data.status_jumlah
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      if (!isActive) {
        this.resetData()
      }
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
